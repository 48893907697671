import timg1 from '../images/team/img-1.jpg'
import timg2 from '../images/team/img-2.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/img-4.jpg'


const Teams = [
    {
      id: '1',
       tImg:timg1,
       name:'Quinton Wheeler',
       title:'Photographer',     
    },
    {
       id:'2',
       tImg:timg2,
       name:'Robert William',
       title:'Wedding Planner',     
    },
    {
       id:'3',
       tImg:timg3,
       name:'Henry D’suza',
       title:'Assistant',     
    },
    {
       id:'4',
       tImg:timg4,
       name:'Jenefer Abram',
       title:'Lead Assistant',     
    }
    
]

export default Teams;